.grid-height_5 {
  height: 239px
}
.grid-height_10 {
  height: 404px
}
.grid-height_20 {
  height: 734px
}

.grid-wrapper {
  width: 100%;
  height: calc(100% - 37px)
}

.paging-wrap {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 37px;
  padding: 0 8px;
  border-top: 1px solid #c5cad0;
  background-color: #f5f6f9;
  color: #626b7a;
  &::after {
    content: '';
    position: absolute;
    bottom: -1px;
    left: -1px;
    right: -1px;
    border-bottom: 1px solid #858f9c;
  }
  select {
    min-width: 95px;
    height: 24px;
    line-height: 22px;
    margin-right: 8px;
    background-image: url(../images/bullet/select_arr_g.png)
  }
  .btn_comm {
    width: 24px;
    height: 24px;
  }
  .btnLabel_icon {
    background-image: url(../images/bullet/page_arr.png);
    &.prev {
      transform: scaleX(-1);
    }
  }
  .page-num {
    padding: 0 15px;
  }
}



.ag-theme-alpine {
  font: inherit;
  .ag-root-wrapper {
    border: 0 hidden
  }
  .ag-header {
    border-bottom-color: #fff;
  }
  .ag-header-viewport {
    border-bottom: 1px solid #c5cad0;
    border-right: 1px solid #fff;
    background: #f5f6f9;
  }
  .ag-header-row {
    height: 36px;
    color: #071e3f;
    font-size: 13px;
    font-weight: 400;
    background: none;
    box-shadow: inset 0 1px 0 #fff;
  }
  .ag-header-cell {
    border-left: 1px solid #fff;
    border-right: 1px solid #e0e2e5;
    box-shadow: inset 0 -1px 0 #fff;
    &:last-child {
      border-right: 0;
      .ag-header-cell-resize::after {
        display: none
      }
    }
  }
  .ag-header-cell-resize::after {
    top: 0;
    height: 100%;
    background-color: #fff;
  }

  .ag-row {
    height: 33px;
    color: #7a8393;
    font-size: 12px;
    border-color: #f4f6f8;
    border-bottom-style: none;
    border-top-style: solid;
    &.ag-row-first {
      border-top-color: #ebedee;
    }
    &.ag-row-odd {
      background-color: #f9fafc
    }
    &.ag-row-hover {
      border-color: #dfebf9;
      background-color: #e9f3ff;
      & + .ag-row {
        border-top-color: #dfebf9;
      }
    }
    &.ag-row-focus, &.ag-row-selected {
      border-color: #d2e4f9 !important;
      background-color: #dbecff !important;
      & + .ag-row {
        border-top-color: #d2e4f9 !important;
      }
    }
  }
  .ag-ltr {
    .ag-cell {
      border-right-color: #eff1f3;
    }
    .ag-row-hover .ag-cell {
      border-right-color: #dbe7f4;
    }
    .ag-row-focus .ag-cell,
    .ag-row-selected .ag-cell {
      border-right-color: #cee1f5 !important;
    }
    .ag-has-focus .ag-cell-focus:not(.ag-cell-range-selected) {
      border-color: #a0bff9;
    }
  }

  .ag-cell {
    line-height: 30px
  }
  .ag-sort-ascending-icon,
  .ag-sort-descending-icon {
    margin-left: 15px;
    .ag-icon {
      width: 15px;
      height: 14px;
      border-radius: 2px;
      background: #ccd0d7 url(../images/bullet/sort_arr.png) no-repeat center center;
      &::before {
        display: none;
      }
      &.ag-icon-asc {
        transform: scaleY(-1);
      }
      &:hover {
        background-color: #007aff;
      }
    }
  }
  .ag-header-cell-menu-button {
    opacity: 1;
    width: 15px;
    height: 15px;
    background: url(../images/bullet/grid_hamburger.png) no-repeat center top;
    &:hover {
      background-position-y: -15px
    }
    .ag-icon {
      &::before {
        display: none;
      }
    }
  }
  .ag-column-menu-visible .ag-header-cell-menu-button {
    background-position-y: -15px
  }
  .ag-menu {
    border: 1px solid #071e3f;
    border-radius: 3px;
    background: #fff;
    box-shadow: 0 0 5px rgba(0,0,0,0.2);
  }
  .ag-filter-select { display: none }
  input {
    box-shadow: none !important;
  }
  .ag-simple-filter-body-wrapper {
    padding: 0;
    & > * {
      margin-bottom: 0
    }
  }
  input[class^='ag-'][type='date'],
  input[class^='ag-'][type='text']{
    border: 0;
    height: 32px;
    border-radius: 2px;
    padding: 5px 10px
  }
}
