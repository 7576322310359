@font-face {
	font-family: 'Noto';
	src: url('../../fonts/NotoSansKR/NotoSans-Regular.eot');
	src: url('../../fonts/NotoSansKR/NotoSans-Regular.eot?#iefix') format('embedded-opentype'),
         url('../../fonts/NotoSansKR/NotoSans-Regular.woff2') format('woff2'),
         url('../../fonts/NotoSansKR/NotoSans-Regular.woff') format('woff');
         /*url('../../fonts/NotoSansKR/NotoSans-Regular.ttf') format('truetype'),*/
         /*url('../../fonts/NotoSansKR/NotoSans-Regular.svg#notokr-Regular') format('svg');*/
	font-weight: normal;
	font-style: normal;
}

@font-face {
  font-family: 'Noto';
  src: url('../../fonts/NotoSansKR/NotoSans-Medium.eot');
  src: url('../../fonts/NotoSansKR/NotoSans-Medium.eot?#iefix') format('embedded-opentype'),
  url('../../fonts/NotoSansKR/NotoSans-Medium.woff2') format('woff2'),
  url('../../fonts/NotoSansKR/NotoSans-Medium.woff') format('woff');
  /*url('../../fonts/NotoSansKR/NotoSans-Medium.ttf') format('truetype'),
  url('../../fonts/NotoSansKR/NotoSans-Medium.svg#notokr-Medium') format('svg');*/
  font-weight: bold;
  font-style: normal
}

@font-face {
  font-family: 'Noto';
  src: url('../../fonts/NotoSansKR/NotoSans-Light.eot');
  src: url('../../fonts/NotoSansKR/NotoSans-Light.eot?#iefix') format('embedded-opentype'),
  url('../../fonts/NotoSansKR/NotoSans-Light.woff2') format('woff2'),
  url('../../fonts/NotoSansKR/NotoSans-Light.woff') format('woff');
  /*url('../../fonts/NotoSansKR/NotoSans-Light.ttf') format('truetype'),
  url('../../fonts/NotoSansKR/NotoSans-Light.svg#notokr-Light') format('svg');*/
  font-weight: 300;
  font-style: normal
}
